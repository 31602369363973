import styled from "styled-components/macro"

export const AppStyles = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }
`
